/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function importComponent(path) {
  return () => import(`./components/${path}.vue`)
}

const guardIT = function (to, from, next) {
  if (to.meta.requireAuth) {
    let role = localStorage.getItem('role')
    // console.log(role);
    if (role == 1) {
      next()
    } else {
      next({
        path: '/it/login',
      })
    }
  }
}

const guardOperator = function (to, from, next) {
  if (to.meta.requireAuth) {
    let role = localStorage.getItem('role')
    // console.log(role);
    if (role == 3) {
      next()
    } else {
      next({
        path: '/',
      })
    }
  }
}
const guardAU = function (to, from, next) {
  if (to.meta.requireAuth) {
    let role = localStorage.getItem('role')
    // console.log(role);
    if (role == 2) {
      next()
    } else {
      next({
        path: '/au/login',
      })
    }
  }
}

const guardMabesAU = function (to, from, next) {
  if (to.meta.requireAuth) {
    let role = localStorage.getItem('role')
    // console.log(role);
    if (role == 4) {
      next()
    } else {
      next({
        path: '/mabesau/login',
      })
    }
  }
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/AddFsc',
      beforeEnter: (to, from, next) => {
        guardOperator(to, from, next)
      },
      name: 'AddFsc',
      meta: { title: 'AddFsc', requireAuth: true },
      component: importComponent('Operator/AddFsc'),
    },
    {
      path: '/au/login',
      name: 'AuLogin',
      meta: { title: 'Login' },
      component: importComponent('AU/AuLogin'),
    },
    {
      path: '/au/',
      meta: { title: 'homeau' },
    },
    {
      path: '/it/',
      meta: { title: 'homeit', requireAuth: true },
    },
    {
      path: '/mabesau/',
      meta: { title: 'homemabesau', requireAuth: true },
    },
    {
      path: '/au/dashboard',
      beforeEnter: (to, from, next) => {
        guardAU(to, from, next)
      },
      name: 'Dashboard',
      meta: { title: 'Flight Dashboard', requireAuth: true },
      component: importComponent('AU/AuDashboard'),
    },
    {
      path: '/au/fcscard',
      beforeEnter: (to, from, next) => {
        guardAU(to, from, next)
      },
      name: 'FscCard',
      meta: { title: 'FCS Card', requireAuth: true },
      component: importComponent('AU/FlightDashboard'),
    },
    {
      path: '/au/fcscardreq',
      beforeEnter: (to, from, next) => {
        guardAU(to, from, next)
      },
      name: 'FscCardReq',
      meta: { title: 'FCS Card Request', requireAuth: true },
      component: importComponent('AU/FlightDashboardRequest'),
    },
    {
      path: '/au/fcscardops',
      beforeEnter: (to, from, next) => {
        guardAU(to, from, next)
      },
      name: 'FscCardOps',
      meta: { title: 'FCS Card Ops', requireAuth: true },
      component: importComponent('AU/FlightDashboardOps'),
    },
    {
      path: '/au/fcstable',
      beforeEnter: (to, from, next) => {
        guardAU(to, from, next)
      },
      name: 'FscTable',
      meta: { title: 'FCS Table', requireAuth: true },
      component: importComponent('AU/FlightTable'),
    },
    {
      path: '/it/login',
      name: 'ITLogin',
      meta: { title: 'Login' },
      component: importComponent('IT/ITLogin'),
    },
    {
      path: '/it/user-dashboard',
      beforeEnter: (to, from, next) => {
        guardIT(to, from, next)
      },
      name: 'UserDashboard',
      meta: { title: 'User Dashboard', requireAuth: true },
      component: importComponent('IT/UserDashboard'),
    },
    // {
    //   path: "/it/office-dashboard",
    //   name: "OfficeDashboard",
    //   meta: { title: "Office Dashboard", requireAuth: true },
    //   component: importComponent("IT/OfficeDashboard"),
    // },
    {
      path: '/it/operator-dashboard',
      beforeEnter: (to, from, next) => {
        guardIT(to, from, next)
      },
      name: 'OperatorDashboard',
      meta: { title: 'Operator Dashboard', requireAuth: true },
      component: importComponent('IT/OperatorDashboard'),
    },
    {
      path: '/',
      name: 'OperatorLogin',
      meta: { title: 'OperatorLogin' },
      component: importComponent('Operator/OperatorLogin'),
    },
    {
      path: '/operator/register',
      name: 'OperatorRegister',
      meta: { title: 'OperatorRegister' },
      component: importComponent('Operator/OperatorRegister'),
    },
    {
      path: '/mabesau/login',
      name: 'MabesauLogin',
      meta: { title: 'MabesauLogin' },
      component: importComponent('Mabesau/MabesauLogin'),
    },
    {
      path: '/mabesau/trend-dashboard',
      beforeEnter: (to, from, next) => {
        guardMabesAU(to, from, next)
      },
      name: 'MabesauDashboard',
      meta: { title: 'Mabesau Dashboard', requireAuth: true },
      component: importComponent('Mabesau/MabesauDashboard'),
    },
    {
      path: '/mabesau/fsc-dashboard',
      beforeEnter: (to, from, next) => {
        guardMabesAU(to, from, next)
      },
      name: 'MabesauTable',
      meta: { title: 'FSC Dashboard', requireAuth: true },
      component: importComponent('Mabesau/MabesauTable'),
    },
    {
      path: '/printOnline/:id',
      name: 'PrintOnline',
      meta: { title: 'Print Online' },
      component: importComponent('PrintOnline'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (
    to.name !== 'OperatorLogin' &&
    document.title == 'AddFsc' &&
    localStorage.getItem('token') === null
  )
    next({ name: 'OperatorLogin' })

  if (
    to.name === 'AuLogin' &&
    localStorage.getItem('token') !== null &&
    localStorage.getItem('role') == 2
  )
    next({ name: 'Dashboard' })
  else next()
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (
    to.name !== 'AuLogin' &&
    (document.title == 'Flight Dashboard' ||
      document.title == 'FCS Card' ||
      document.title == 'FCS Card Ops' ||
      document.title == 'FCS Table' ||
      document.title == 'homeau') &&
    localStorage.getItem('token') === null
  )
    next({ name: 'AuLogin' })

  if (
    to.name === 'AuLogin' &&
    localStorage.getItem('token') !== null &&
    localStorage.getItem('role') == 2
  )
    next({ name: 'Dashboard' })
  else next()
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title

  if (
    to.name !== 'ITLogin' &&
    (document.title == 'User Dashboard' ||
      document.title == 'Office Dashboard' ||
      document.title == 'Operator Dashboard' ||
      document.title == 'homeit') &&
    localStorage.getItem('token') === null
  )
    next({ name: 'ITLogin' })

  if (
    to.name === 'ITLogin' &&
    localStorage.getItem('token') !== null &&
    localStorage.getItem('role') == 1
  )
    next({ name: 'UserDashboard' })
  else next()
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (
    to.name !== 'MabesauLogin' &&
    (document.title == 'Mabesau Dashboard' ||
      document.title == 'Fsc Dashboard' ||
      document.title == 'homemabesau') &&
    localStorage.getItem('token') === null
  )
    next({ name: 'MabesauLogin' })

  if (
    to.name === 'MabesauLogin' &&
    localStorage.getItem('token') !== null &&
    localStorage.getItem('role') == 4
  )
    next({ name: 'MabesauDashboard' })
  else next()
})
export default router
